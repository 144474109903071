.community-container {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .comment-reply,
  .comment-sub-reply {
    margin-left: 8px;
    padding-left: 18px;
    border-left: 1px solid #d8e0ec;
  }


  .btn-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    min-width: 160px;
    min-height: 48px;
    padding: 0.875rem 1.2rem;
    border-radius: 10px;
    border: none;
    .icon {
      z-index: 11;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
      z-index: 11;
    }
    &:hover {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.1);
        z-index: 1;
      }
    }
    &:disabled {
      background: #d2ddec !important;
    }
  }
  .community-banner {
    min-height: 170px;
    img {
      border-radius: 10px;
      max-height: 170px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .bar-btn {
    button {
      font-weight: 500;
    }
  }
  .btn-delete {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    svg {
      width: 1rem;
      height: 1rem;
      --stroke-color: #ec3030;
    }
    span {
      color: #ec3030;
      font-size: 13px;
      font-weight: 400;
      margin-left: 8px;
    }
  }
  .btn-close {
    background: none;
    border: none;
    color: #11294f;
    font-size: 14px;
    font-weight: 500;
    margin-right: 16px;
  }
  .main-title {
    margin: 0;
    display: flex;
    align-items: center;
    .icon {
      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
    span {
      color: #11294f;
      font-size: 20px;
      font-weight: 500;
      margin-left: 10px;
    }
  }
  .title-para {
    color: #667182;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
  }
  .badge-icon {
    width: 1rem;
    height: 1rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .user-badge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .badge {
      border-radius: 10px;
      border: 1px solid #d2ddec;
      background: #fff;
      padding: 0.3rem 0.7rem;
      display: flex;
      align-items: center;
      margin-left: 0.4rem;
      margin: 1px;
      &:first-child {
        margin-left: 0rem;
      }
      span {
        margin-left: 0.5rem;
        color: #7e8da0;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    .extra-badge-count {
      color: #7e8da0;
      font-size: 12px;
      font-weight: 400;
      margin-left: 8px;
    }
  }
  .loading {
    text-align: center;
    img {
      margin-top: 0.4rem;
      width: 48px;
      height: 48px;
    }
    p {
      color: #344054;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .community-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-bar {
      width: 100%;
      position: relative;
      .search-field {
        position: relative;
        // margin: 0.75rem 0;
        .search-icon,
        .clear-search {
          position: absolute;
          top: 32%;
          cursor: pointer;
        }
        .clear-search {
          right: 1rem;
          svg {
            width: 0.75rem;
            height: 0.75rem;
            --stroke-color: #7e8da0;
          }
        }
        .search-icon {
          left: 1rem;
          svg {
            width: 1.1rem;
            height: 1.1rem;
          }
        }
        input {
          border-radius: 10px;
          border: 1px solid #ecf0f6;
          background: #fff;
          height: 48px;
          width: 100%;
          padding: 0 2.875rem;
        }
      }
      .searching-list {
        display: block;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 11;
        border-radius: 10px;
        border: 0.5px solid #70839c;
        background: #fff;
        box-shadow: 0px 1px 15px 0px rgba(17, 41, 79, 0.15);
        padding: 0 1rem 1rem 1rem;
        margin-top: 0.4rem;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        .list-item {
          margin-top: 1rem;
        }
        // .empty-search-list {

        //     padding: 2rem 0 1rem 0;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        // }
      }
    }
    .bar-btn {
      margin-left: 0.875rem;
    }
  }

  .post-list-mark {
    margin: 0 1rem 0 0;
    display: flex;
    align-items: center;
    justify-content: right;
    .border {
      width: 1px;
      height: 20px;
      background: #d5d9df;
      margin: 0 1rem;
    }
    .post-list-mark-left {
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
      button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin-left: auto;
        span {
          margin-left: 6px;
          color: #2d405d;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
    .post-list-mark-right {
      display: flex;
      align-items: center;
      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
      span {
        margin-left: 8px;
        color: #2d405d;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .room-widget {
    display: flex;
    align-items: center;
    h6 {
      display: flex;
      align-items: center;
      margin: 0;
      margin-right: 8px;
      span {
        color: #11294f;
        font-size: 1rem;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
    .room-type {
      border-radius: 15px;
      background: #eeeaff;
      padding: 0.3rem 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1rem;
        height: 1rem;
      }
      span {
        color: #2d405d;
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 3px;
      }
    }
  }

  .total-followers {
    display: flex;
    align-items: center;
    svg {
      width: 1.2rem;
      height: 1.2rem;
      --fill-color: white;
    }
    strong {
      color: #11294f;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0 6px;
    }
    span {
      color: #667182;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  .menu-wrapper {
    position: relative;
    button {
      background: none;
      border: none;
      padding: 0;
    }
    .btn-post-menu {
      border-radius: 8px;
      padding: 1rem 0.5rem;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;
      svg {
        width: 1rem;
        height: 3px;
      }
    }
    .btn-post-menu.active {
      border: 1px solid #d5d9df;
      background: #f7fafc;
    }

    .toggle-menu {
      position: absolute;
      top: 2.6rem;
      right: 0px;
      margin: 0;
      padding: 0;
      list-style: none;
      border-radius: 10px;
      border: 0.5px solid #70839c;
      background: #fff;
      box-shadow: 0px 1px 15px 0px rgba(17, 41, 79, 0.15);
      min-width: 190px;
      padding: 22px 18px;
      z-index: 99;
      transition: all 0.2s ease-in-out;
      li {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 0.75rem;
      }
    }
    .close-menu {
      position: absolute;
      right: 16px;
      top: 14px;
      svg {
        width: 0.75rem;
        height: 0.75rem;
        --stroke-color: #7e8da0;
      }
    }
    .btn-post-menu-item {
      display: flex;
      align-items: center;
      .icon-wrapper {
        svg {
          --stroke-color: #2d405d;
          width: 1rem;
          height: 1rem;
        }
      }
      span {
        color: #4b5d7a;
        font-size: 0.875rem;
        font-weight: 400;
        margin-left: 0.875rem;
        transition: all 0.2 ease-in-out;
      }
      &:hover {
        svg,
        span {
          --stroke-color: #2973e0;
          color: #2973e0 !important;
        }
      }
    }
  }

  .post-card {
    border-radius: 10px;
    border: 1px solid #ecf0f6;
    background: #fff;
    position: relative;
    margin-bottom: 24px;
    .post-card-wrapper {
      padding: 1.4rem 2rem 0rem 1rem;
    }

    .post-tags {
      position: absolute;
      left: 1rem;
      top: -15px;
      .post-tag {
        border-radius: 8px;
        border: 1px solid #d2ddec;
        padding: 0.3rem 0.5rem;
        display: flex;
        align-items: center;
        margin-left: 0.4rem;
        width: fit-content;
        span {
          margin-left: 0.5rem;
          color: #fff;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
    .badge-icon-sm {
      width: 0.75rem;
      height: 0.75rem;
      svg {
        width: 0.75rem;
        // height: 0.75rem;
      }
    }
    .post-card-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .post-card-top-left {
        display: flex;
        align-items: center;
        .user-intro {
          display: flex;
          align-items: center;
          .user-name {
            color: #11294f;
            // font-size: 0.875rem;
            font-size: 0.938rem;
            font-weight: 500;
            margin-left: 0.875rem;
            min-width: fit-content;
          }
          .user-badge {
            margin: 0 1rem;
          }
        }
      }
      .post-card-top-right {
        display: flex;
        align-items: center;
        min-width: fit-content;
        span {
          color: #667182;
          font-size: 0.75rem;
          font-weight: 500;
          margin-right: 0.75rem;
          min-width: fit-content;
        }
      }
    }
    .post-card-bottom {
      margin-top: 0.875rem;
      margin-left: 3rem;
      .about-post-badge {
        cursor: pointer;
        .badge {
          border-radius: 10px;
          border: 1px solid #dcd7ee;
          background: #f7f5ff;
          display: flex;
          align-items: center;
          width: fit-content;
          padding: 0.3rem 0.7rem;
          span {
            color: #667182;
            font-size: 12px;
            font-weight: 400;
            margin: 0 0.4rem;
            text-transform: capitalize;
          }
        }
      }
      .post-title {
        color: #11294f;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 28px;
        margin: 0;
        margin-top: 0.75rem;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .post-para {
        color: #11294f;
        // color: #667182;
        // font-size: 0.8125rem;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.4rem;
        margin: 0;
        margin-top: 1rem;
        p {
          margin: 0.5rem 0 0 0;
          word-break: break-word;
          img {
            margin: 0.5rem 0 0.875rem 0;
          }
        }

        img {
          width: 100%;
          // object-fit: contain;
        }
      }
      .post-footer {
        margin: 1.4rem 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .post-footer-right {
          display: flex;
          align-items: center;
          .border {
            width: 1px;
            height: 20px;
            background: #d5d9df;
            margin: 0 1rem;
          }
          .btn-reply {
            svg {
              --stroke-color: #2973e0;
            }
            span {
              color: #2973e0;
            }
          }
        }
        button {
          background: none;
          border: none;
          display: flex;
          align-items: center;
          padding: 0;
          // margin-left: 2rem;
          &:first-child {
            margin-left: 0rem !important;
          }
          svg {
            width: 1.2rem;
            height: 1.2rem;
          }
          span,
          strong {
            color: #11294f;
            font-size: 0.875rem;
          }
          span {
            font-weight: 400;
            margin-left: 6px;
          }
          .counts {
            display: flex;
            align-items: center;
            strong {
              margin-left: 8px;
              font-weight: 600;
              font-size: 0.875rem;
            }
          }
        }
      }
    }

    .post-card-comments {
      margin: 1.2rem 0;
    }

    .reply-cmt-field {
      border-top: 1px solid #f4f4f4;
      padding: 1rem;
    }

    @media screen and (max-width: 767px) {
      .post-card-bottom {
        margin-left: 0rem;
        .post-footer {
          margin: 1rem 0 0 0;
          .post-footer-left {
            button {
              margin-left: 1.5rem;
              // span {
              //     display: none;
              // }
            }
          }
        }
      }
    }
    @media screen and (max-width: 600px) {
      .post-card-wrapper {
        padding: 1.4rem 1rem 0rem 1rem;
      }
      .post-card-top {
        align-items: start;
        .post-card-top-left {
          flex-direction: column;
          align-items: start;
          .user-intro {
            margin-top: 0.5rem;
            .user-name {
              margin-left: 0rem;
            }
            .user-badge {
              margin: 0 0.5rem;
              .badge {
                padding: 0.2rem 0.6rem;
                margin-left: 0.3rem;
                span {
                  margin-left: 0.4rem;
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  .card-body {
    padding: 1.5rem 1.8rem;
    border-radius: 10px;
    border: 1px solid #ecf0f6;
    background: #fff;
    margin-bottom: 0.875rem;
    .card-icon {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 18px 0 0;
      .icon {
        width: 20px;
        height: 20px;
        use {
          width: 100%;
          height: 100%;
        }
      }
    }
    .card-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #11294f;
      margin: 0;
    }
    .card-sub-title {
      color: #11294f;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
    p {
      color: #667182;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .card-margin {
      margin-top: 0.875rem;
      padding: 0 3.4rem;
    }

    @media screen and (max-width: 767px) {
      padding: 1.5rem 1rem;
      .card-margin {
        padding: 0;
      }
    }
  }

  .setting-wrapper {
    .setting-top {
      margin-top: 20px;
      .label {
        color: #11294f;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        &.disabled-room {
          strong {
            color: #a1b1c6;
          }
        }
        strong {
          color: #06c575;
          font-weight: 600;
        }
      }
    }
    .setting-details,
    .setting-access,
    .setting-destroy {
      .card-margin {
        margin-top: 0.5rem;
        padding: 0 1.6rem 0 3.5rem;
      }
    }

    .setting-details {
      .card-icon {
        background-color: rgba(250, 178, 39, 0.1);
      }
      .mt-1 {
        margin-top: 1.6rem;
      }
      .form-group {
        .example-chip-list {
          width: 100%;
        }
        .label {
          color: #11294f;
          font-size: 14px;
          font-weight: 500;
          line-height: 26px;
        }
        label {
          color: #64758c;
          font-size: 11px;
          font-weight: 400;
          margin-top: 8px;
        }
        .form-upload {
          margin-top: 0.75rem;
        }
      }
      p {
        margin-bottom: 1rem;
        .learn-more {
          text-decoration: underline;
        }
      }
    }
    .setting-access {
      .card-icon {
        background-color: #e9f2ff;
      }
      .setting-access-list {
        margin-bottom: 20px;
        li {
          display: flex;
          align-items: start;
          margin-top: 1rem;
          .bg-1 {
            background: #ffe3c2;
          }
          .bg-2 {
            background: #caffe9;
          }
          .bg-3 {
            background: #cee2ff;
          }
          .bg-4 {
            background: #ece7ff;
          }
          .image-icon {
            max-width: 37px;
            width: 100%;
            height: 37px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 22px;
            img {
              width: 22px;
              height: 22px;
            }
          }
          .item-content {
            .item-para {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
        .item-title {
          color: #11294f;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;
          margin: 0;
        }
      }
      .paid-access-content {
        border-radius: 10px;
        border: 1px solid #ecf0f6;
        background: #fff;
        margin-top: 1rem;
        margin-left: 2rem;
        padding: 20px 40px;
        .sub-palns {
          .form-group {
            margin-bottom: 0;
            background-color: #fff;
            input {
              max-width: 90px;
            }
          }
        }
        .item-title {
          font-weight: 600;
          margin-bottom: 0.75rem;
        }
        .ml-1 {
          margin-left: 1.2rem;
        }
        .label {
          color: #667182;
          font-size: 13px;
          font-weight: 400;
          line-height: 42px;
          margin-right: 10px;
        }
        .room-benifit {
          margin-top: 1rem;
          .form-group {
            margin-bottom: 0px;
          }
          li {
            align-items: center;
            padding: 0.75rem 0;
            border-bottom: 1px solid #e8edf4;
            margin-top: 0px;
            &:last-child {
              border-bottom: 1px solid transparent;
              padding-bottom: 0px;
            }
          }
          .item-left {
            svg {
              --stroke-color: #11294f;
              width: 1rem;
              height: 0.75rem;
            }
          }
          .item-right {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            span {
              color: #2d405d;
              font-size: 13px;
              font-weight: 400;
              text-transform: capitalize;
            }
            svg {
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
            .icon-container {
              display: flex;
              justify-content: space-between;
              width: 40px;
              align-items: center;

              :nth-child(1) {
                display: flex;
              }
            }
          }
        }
      }

      .setting-access-btns {
        margin-top: 24px;
        .btn-admin-primary {
          min-width: 110px;
          height: 37px;
        }
      }
      .room-benifit {
        .warn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 10px;
          background: #ffeef0;
          padding: 0.75rem 0.875rem;
          margin-top: 0.875rem;
          .warn-left {
            display: flex;
            align-items: center;
            .icon {
              width: 1.2rem;
              height: 1.2rem;
              svg {
                width: 1.2rem;
                height: 1.2rem;
              }
            }
            span {
              color: #667182;
              font-size: 13px;
              font-weight: 400;
              text-transform: capitalize;
              margin: 0 0.875rem;
            }
          }
          .close-icon {
            cursor: pointer;
            svg {
              width: 0.875rem;
              height: 0.875rem;
              --stroke-color: #667182;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .card-margin {
        padding: 0 !important;
      }
      .paid-access-content {
        margin-left: 0px !important;
        padding: 1rem 0.5rem !important;
      }
    }
    // @media screen and (max-width: 480px) {
    //     .form-upload {
    //         ::ng-deep .file-upload-container {
    //             height: 72px;
    //         }
    //     }
    // }
  }

  .reply-cmt-field {
    // overflow: hidden;
    .reply-cmt-field-wrapper {
      // position: relative;
      border-radius: 16px;
      background: transparent;
      // overflow: hidden;

      border-radius: 16px;
      border: 1px solid #cdd6e3;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 4px;
    }

    ::-webkit-scrollbar {
      width: 6px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(217, 217, 227, 0.8);
      border-radius: 4px;
    }

    .field-actions {
      // position: absolute;
      right: 2%;
      bottom: 0.76rem;
      display: flex;
      align-items: center;
      .btn-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      .btn-send {
        padding: 6px 1rem;
        border-radius: 16px;
        border: 1px solid #cdd6e3;
        background: #2973e0;
        svg {
          width: 20px;
          height: 24px;
          --stroke-color: white;
        }
        &:focus,
        &:hover,
        &:active {
          background: #2973e0;
          svg {
            --stroke-color: white;
          }
        }
      }
      .btn-emoji {
        border: none;
        background: none;
        padding: 0;
        margin-right: 0.75rem;
        svg {
          width: 26px;
          height: 26px;
        }
      }
    }
    textarea {
      white-space: pre-wrap;
      box-sizing: border-box;
      border: none;
      padding: 0.875rem 0.5rem;
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.2rem;
      font-weight: 400;
      resize: none;
      font-family: Inter;
      vertical-align: middle;
      min-height: 48px;
      height: 100%;
      &::placeholder {
        color: #9aa4b3;
      }
      &:focus {
        outline: none;
        // border: 1px solid #2973e0;
      }
    }
  }
  @media screen and (max-width: 480px) {
    // .reply-cmt-field {
    //     padding: 1rem 0.5rem !important;
    //     textarea {
    //         padding: 1rem 7rem 0rem 3.5rem;
    //     }
    // }
    .community-banner {
      img {
        height: 170px;
      }
    }
    .user-badge {
      .badge {
        padding: 0.3rem;
      }
    }
    .community-search {
      flex-direction: column-reverse;
      .bar-btn {
        margin-bottom: 7px;
        margin-left: 0 !important;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}
