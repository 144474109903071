.analytics {
  .btn-link {
    margin: 0;
    padding: 0;
  }

  .box-item {
    border-radius: 0.625rem;
    background: #fff;
    padding: 1.2rem 1.6rem;
    text-align: center;
    height: 100%;
    .item-content {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .icon {
        svg {
          width: 1.75rem;
          height: 1.75rem;
        }
      }
      .text {
        text-align: end;
        h5 {
          color: #11294f;
          font-size: 2rem;
          font-weight: 500;
          line-height: 2rem; /* 126.667% */
          margin: 0;
          margin-bottom: 0.3rem;
        }
        span {
          color: #11294f;
          font-size: 0.75rem;
          font-weight: 400;
          letter-spacing: -0.015rem;
        }
      }
    }
    .item-des {
      margin-top: 1rem;
      color: #667182;
      // font-size: 0.75rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.2rem;
      text-align: center;
    }
  }

  .chart-title {
    color: #11294f;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.015rem;
    text-align: center;
    margin: 0;
    margin-bottom: 1.6rem;
  }
  .analytics-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
    .header-wrapper {
      .title {
        display: flex;
        align-items: center;
        column-gap: 0.6rem;
        .icon {
          svg {
            width: 23px;
            height: 23px;
          }
        }
        h6 {
          color: #45377d;
          font-size: 1.4rem;
          font-weight: 500;
          margin: 0;
          text-transform: capitalize;
        }
      }
      .des {
        color: #667182;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.2rem; /* 163.636% */
        margin-bottom: 0;
      }
    }
  }
  .analytics-header-actions {
    button {
      min-width: 88px;
      display: flex;
      align-items: center;
      column-gap: 0.4rem;

      .icon {
        svg {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
      span {
        color: #2973e0;
        font-size: 0.75rem;
        font-weight: 600;
      }
    }
    .btn-export {
      padding: 0;
      background: none;
      border: none;
    }
  }

  .feature-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 0.625rem;
    background: #eef3fa;
    padding: 3rem 1rem;
    .feature-plan-content {
      max-width: 16rem;
      h6 {
        color: #11294f;
        font-size: 1.125rem;
        font-weight: 500;
        margin: 0;
      }
      p {
        color: #667182;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3rem;
      }
      .content-btn {
        margin-top: 1.6rem;
        .btn-plan-upgrade {
          border-radius: 0.625rem;
          background: #7f56d9;
          width: 8.5rem;
          height: 2.25rem;
          border: none;
          text-align: center;
          color: white;
        }
      }
    }
    .feature-img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        min-height: 18rem;
        object-fit: cover;
      }
    }

    @media screen and (min-width: 991px) {
      min-height: 25rem;
      padding: 2rem 2.5rem;
      gap: 0.5rem;
      .feature-plan-content {
        .content-btn {
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      .feature-plan-content {
        max-width: 100%;
      }
    }
  }

  .table-wrapper {
    border-radius: 0.625rem;
    background: #fff;
    padding: 1.2rem 0.8rem;
    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.8rem;
      .title {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        svg {
          width: 1.875rem;
          height: 1.875rem;
        }
        h6 {
          color: #11294f;
          font-size: 1.2rem;
          font-weight: 500;
          letter-spacing: -0.0175rem;
          text-transform: capitalize;
          margin: 0;
        }
      }
      .table-header-tabs {
        min-width: 18rem;
        min-height: 2.75rem;
        display: flex;
        align-items: center;
        border: 1px solid #ecf0f6;
        background: #f6f9fd;
        border-radius: 0.625rem;
        button {
          flex: 1;
          min-height: 2.75rem;
          background: none;
          border: none;
          &.active {
            background: #fff;
          }
        }
      }
    }
    .table-responsive {
      overflow-x: auto;
      .table {
        width: 100%;
        //   border-collapse: collapse;
        border-collapse: separate; /* Changed from collapse to separate */
        border-spacing: 0;
        th,
        td {
          padding: 1rem 0.5rem;
        }
        .thead {
          background: #f7fafc;
          th {
            color: #11294f;
            font-size: 0.875rem;
            font-weight: 500;
            min-width: 6rem;
            text-align: start;
            &:first-child {
              border-top-left-radius: 0.625rem;
            }
            &:last-child {
              border-top-right-radius: 0.625rem;
            }
          }
          .th-sm {
            min-width: 4rem;
          }
          .th-md {
            min-width: 10rem;
          }
          .th-lg {
            min-width: 15rem;
          }
        }
        .tbody {
          tr {
            transition: background-color 0.3s ease-in-out;
            cursor: pointer;
            &:hover {
              background-color: #eff6ff;
            }
            td {
              border-bottom: 1px solid #f4f4f4;
              color: #475467;
              font-size: 13px;
              font-weight: 400;
              line-height: normal;
              // line-height: 2.75rem;
              &:last-child {
                border-bottom: 1px solid transparent;
              }
              .btn-table-detail {
                border: none;
                background: none;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                column-gap: 0.4rem;
                padding: 0px;
                svg {
                  width: 0.75rem;
                  height: 0.75rem;
                }
                span {
                  color: #2973e0;
                  font-weight: 400;
                }
              }
              .user-bio {
                display: flex;
                align-items: center;
                column-gap: 0.7rem;
                .user-img {
                  min-width: 1.7rem;
                  height: 1.7rem;
                  background: #d9d9d9;
                  border-radius: 50%;
                  overflow: hidden;
                  img {
                    width: 1.7rem;
                    height: 1.7rem;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }

        .text-center {
          text-align: center !important;
        }

        .paymentMethod {
          color: #2973e0;
          font-weight: 400;
        }
      }
    }
    .table-footer {
      table-paginator {
        .table-paginator-main-div {
          padding: 1.5rem 0.5rem 1rem 0.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .analytics-header {
      flex-direction: column;
      margin-bottom: 1rem;
      .analytics-header-actions {
        margin-left: auto;
        .btn-export {
          min-width: 100%;
        }
      }
    }
  }
}
