// for switch/slider toggle
.round-switch-sec {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.round-switch-text {
  margin-right: 0.35rem;
  margin-top: -2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  color: var(--text-primary);
}

.round-switch {
  margin-left: 0.35rem;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 19px;
}

.round-switch .round-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.round-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #AEBACA;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.round-slider:before {
  position: absolute;
  content: "";
  height: 13.5px;
  width: 13.5px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.round-switch-input:checked+.round-slider {
  background-color: var(--text-primary);
}

.round-switch-input:focus+.round-slider {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.round-switch-input:checked+.round-slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.round-slider.round-circle {
  border-radius: 34px;
}

.round-slider.round-circle:before {
  border-radius: 50%;
}
