.form-checkbox {
  display: flex;
  align-items: start;
  .form-checkbox-input {
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
    border: 1px solid #a1b1c6;
    background-color: var(--color-white);
    box-shadow: none;
    position: relative;
    cursor: pointer;
    margin: 0;

    &:checked {
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
      background-color: var(--text-primary);
    }
  }
  span {
    padding-left: 12px;
  }
}

