.activate-plan {
    width: 100%;
    padding: 120px 0px;
    position: relative;
    .redeem-card {
        background-color: #fff;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
        .redeem-card-header {
            background-color: #11294f;
            width: 100%;
            position: relative;
            padding: 0 1.8rem;
            padding-top: 0.75rem !important;
            .bg-img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 20rem;
                height: auto;
            }
            .redeem-title {
                width: 70%;
                h1 {
                    font-size: 32px;
                    line-height: 38px;
                    color: #ffffff;
                    font-weight: 700;
                    margin: 0px;
                    margin-bottom: 0.8rem;
                }
                span {
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                }
                .material-symbols-outlined {
                    padding-right: 10px;
                    font-size: 17px;
                }
            }
            .redeem-img {
                width: 30%;
                margin-bottom: -3px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .redeem-card-body {
            padding: 1rem 2rem;
            text-align: center;
            h2 {
                font-size: 30px;
                line-height: 45px;
                color: #0f274a;
                font-weight: 600;
                margin: 0;
                margin-bottom: 0.875rem;
            }
            p {
                color: #2d343f;
                font-size: 0.875rem;
                font-weight: 500;
                margin: 0 0 1rem 0;
            }
            .lifetime-access-card {
                border-radius: 4px;
                background-color: #e6f1ff;
                padding: 1.2rem 3.5rem;
                position: relative;
                overflow: hidden;
                margin-bottom: 2rem;
                .discount-label {
                    position: absolute;
                    top: 5px;
                    left: -25px;
                    background-color: #144f96;
                    color: #ffffff;
                    font-size: 13px;
                    font-weight: 700;
                    padding: 0.4rem 2rem 0.4rem 1.9rem;
                    transform: rotate(-40deg);
                    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
                }
                h3,
                .discount-price {
                    color: #144f96;
                    font-size: 22px;
                    margin-bottom: 8px !important;
                }
                .dashed-price {
                    text-decoration: line-through;
                }
                .discount-price {
                    font-weight: 700;
                    position: relative;
                    padding: 0px 0.5rem;
                    img {
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 100%;
                        height: auto;
                        margin: auto;
                    }
                }
                h3 {
                    font-weight: 600;
                    padding-left: 5px;
                    margin: 0px;
                }
                p {
                    color: #144f96;
                    font-size: 13px;
                    font-weight: 400;
                }
                .btn-danger {
                    background-color: #ef0707;
                    border: none;
                    padding: 0.875rem 1.5rem;
                    border-radius: 4px;
                    margin: auto;
                    cursor: pointer;
                    span {
                        color: #ffffff;
                        font-size: 13px;
                        font-weight: 600;
                    }
                    .material-symbols-outlined {
                        font-size: 1rem;
                        font-weight: 500;
                        padding-right: 8px;
                    }
                }
            }
        }
        .redeem-card-bottom {
            padding: 1rem 1.8rem;
            .comment-icon {
                width: 2rem;
                height: 2rem;
                border-radius: 0px 14px 14px 14px;
                background-color: #11294f;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    font-size: 1rem;
                    color: white;
                }
            }
            .comment-details {
                padding-left: 2rem;
                h4 {
                    color: #0f274a;
                    margin: 0;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 600;
                }
                span {
                    color: #536787;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 400;
                }
            }
        }
    }
    @media screen and (min-width: 991px) {
        max-width: 800px;
        height: auto;
        margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
        padding: 80px 0px;
        .redeem-card-header {
            align-items: end !important;
            padding: 0 1rem !important;
            .redeem-title {
                margin-bottom: 4rem;
            }
        }
        .redeem-card-body {
            padding: 1rem !important;
            h2 {
                font-size: 22px;
            }
            p {
                font-size: 0.875rem;
            }
            .lifetime-access-card {
                padding: 1.2rem 2rem !important;

                h3,
                .discount-price {
                    font-size: 18px !important;
                }
            }
            .discount-price {
                img {
                    top: 8px !important;
                }
            }
        }
        .redeem-card-bottom {
            padding: 1rem !important;
            .comment-icon {
                width: 4rem !important;
            }
        }
        .footer {
            display: none;
        }
    }

    .footer {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 10px;
    }
}
