.page-builder {
  height: 100%;
  .main-title {
    display: flex;
    align-items: center;
    margin: 0;
    .icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    span {
      margin-left: 0.75rem;
      color: #11294f;
      font-size: 22px;
      font-weight: 500;
    }
  }
  .main-des {
    color: #667182;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    margin-top: 0.75rem;
  }
  .sub-title {
    color: #2d405d;
    font-size: 14px;
    font-weight: 500;
    margin: 0.75rem 0;
  }

  .btn-hint {
    margin-top: 1.6rem;
    text-align: center;
    p {
      color: #475467;
      font-size: 0.75rem;
      line-height: 1.875rem;
      margin: 0;
    }
  }

  .btn-trans {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    svg {
      width: 1rem;
      height: 1rem;
      --stroke-color: var(--text-link);
    }
    span {
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--text-link);
      margin-left: 6px;
    }
  }
  .search-bar {
    width: 100%;
    position: relative;
    .search-field {
      position: relative;
      // margin: 0.75rem 0;
      .search-icon,
      .clear-search {
        position: absolute;
        top: 32%;
        cursor: pointer;
      }
      .clear-search {
        right: 1rem;
        svg {
          width: 0.75rem;
          height: 0.75rem;
          --stroke-color: #7e8da0;
        }
      }
      .search-icon {
        left: 1rem;
        svg {
          width: 1.1rem;
          height: 1.1rem;
        }
      }
      input {
        border-radius: 10px;
        border: 1px solid #ecf0f6;
        background: #fff;
        height: 48px;
        width: 100%;
        padding: 0 2.875rem;
      }
    }
    .searching-list {
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 11;
      border-radius: 10px;
      border: 0.5px solid #70839c;
      background: #fff;
      box-shadow: 0px 1px 15px 0px rgba(17, 41, 79, 0.15);
      padding: 0 1rem 1rem 1rem;
      margin-top: 0.4rem;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      .list-item {
        margin-top: 1rem;
      }
      // .empty-search-list {

      //     padding: 2rem 0 1rem 0;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      // }
    }
  }

  .create-new-page-form {
    .btn-link {
      padding: 0;
    }
    .create-new-page-form-top {
      margin-top: 1.2rem;
      h6 {
        svg {
          width: 18px;
          height: 18px;
        }
        span {
          font-size: 18px;
        }
      }
    }
    .create-new-page-form-bottom {
      border-radius: 10px;
      border: 1px solid #ecf0f6;
      background: #fff;
      padding: 30px;
      margin-top: 0.75rem;
      .form-group {
        .main-label {
          color: #475467;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
          margin-bottom: 5px;
        }
        label {
          color: #667085;
          margin-bottom: 0.5rem;
          margin-top: 0rem;
        }
        .form-control,
        textarea {
          background-color: white;
        }
      }
      .action-btn {
        margin-top: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-admin-primary {
          min-width: 172px;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .container {
      max-width: 800px !important;
    }
  }
}

.page-container {
  // max-width: 65rem;
  max-width: 86rem;
  width: 100%;
  margin: auto;
}
.page-sub-container {
  max-width: 65rem;
  width: 100%;
  margin: auto;
}

.media {
  position: relative;
  overflow: hidden;
  border-radius: 1.25rem;
  margin: auto;
  margin-bottom: 1.2rem;
  .media-overlay {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0);
    z-index: 10;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // border-radius: 1.25rem;
  }
  video {
    width: 100%;
    height: 100%;
    border-radius: 1.25rem;
    object-fit: cover;
  }
  .playbtn {
    width: 100px;
    height: 100px;
    background: transparent;
    position: absolute;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 111;
    svg {
      width: 5rem;
      height: 5rem;
    }
  }

  .media-empty-img {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.common-empty-state {
  min-height: 20rem;
  background: #fff;
  padding: 3rem 1rem;
  border-radius: 1.625rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  .empty-state-icon {
    img {
      width: 7.5rem;
      height: 7.5rem;
    }
  }
  .empty-state-text {
    color: #101828;
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: -0.0275rem;
    margin: 0;
  }
}
