:root {
    --text-color: #667182;
    --text-primary: #11294f;
    --text-accent: #eb3b3b;
    --text-secondary: #2d343f;
    --text-link: #2973e0;
    --text-dark-green: #324435;
    --text-dark: rgba(22, 28, 26, 0.87);
    --text: rgba(22, 28, 26, 0.87);

    --text-secondary-light: #f5f5f5;

    --color-white: #ffffff;
    --color-warn: #f44336;

    //
    --bg-color: #f2f2f2;
    //
    --box-shadow: 0px 1px 3px rgba(126, 141, 160, 0.15);
    --box-shadow-primary: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    --border-color: #dfe4ee;
    --border-color-primary: #d7e2ea;

    //fonts
    --fonts: "Inter", "Work Sans", sans-serif;
}
