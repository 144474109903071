.custom-domain-container {
    .copy-btn {
        cursor: pointer;
        svg {
            width: 1.2rem;
            height: 1.2rem;
            --stroke-color: #667182;
        }
    }
    .title-container {
        .icon-holder {
            width: 43px;
            height: 43px;
            margin-right: 10px;
        }
        .title-holder {
            h5,
            h3 {
                margin: 0;
            }
            h5 {
                color: var(--text-link);
                font-size: 12px;
                padding-bottom: 5px;
                font-weight: 700;
            }
            h3 {
                color: var(--text-primary);
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    .info-container {
        margin: 1rem 0;
        padding: 1rem;
        border-radius: 8px;
        background: #fff4f4;
        .warn-icon {
            margin-right: 0.75rem;
            svg {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
        p {
            color: #882828 !important;
            margin-bottom: 0px;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
    .domain-card-content {
        padding: 0 5rem 2rem 5rem;

        .site-details {
            height: auto;
            margin-bottom: 0.8rem;
            padding: 0.75rem 1rem;
            border-radius: 8px;
            background-color: #f4f8ff;
            .label {
                font-weight: 600;
                font-size: 0.875rem;
            }
            span {
                color: #2d343f;
            }
            .site-name {
                font-weight: 500;
                font-size: 18px;
                padding-left: 5px;
            }
            @media screen and (max-width: 400px) {
                span {
                    font-size: 12px;
                }
            }
        }
        p {
            color: var(--text-color);
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 16px;
            // margin-bottom: 1.5rem;
        }
        .field-hint-top {
            margin: 0.75rem 0 1rem 0;
        }
        .field-hint {
            margin: 1rem 0 1.5rem 0;
            a {
                color: var(--text-link);
                text-decoration: none;
            }
        }
        .list {
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 1rem;
            padding: 0;
            list-style: none;
            .list-item {
                .domain-listed-item {
                    padding: 0.8rem 1rem;
                    border-bottom: 1px solid var(--border-color);
                    .title {
                        font-size: 0.875rem;
                        color: #11294f;
                        font-weight: 600;
                        width: 20%;
                        padding-right: 8px;
                    }
                    .name-container {
                        width: 80%;
                        .element-type {
                            font-size: 0.875rem;
                            font-weight: 400;
                            color: var(--text-secondary);
                            line-break: anywhere;
                            width: 90%;
                        }
                        .material-symbols-outlined {
                            cursor: pointer;
                            color: var(--text-color);
                            font-size: 20px;
                            width: 10%;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        flex-wrap: wrap;
                        .title {
                            width: 100%;
                            margin-bottom: 3px;
                        }
                        .name-container {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .title-container {
            .icon-holder {
                display: none;
            }
        }
        .domain-card-content {
            p {
                margin-bottom: 1rem;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .domain-card-content {
            padding: 1rem !important;
        }
    }
}
