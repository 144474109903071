.form-radio {
    .input-form-radio {
        height: 1rem;
        width: 1rem;
        font-size: 1rem;
        background-color: white;
        border: 1px solid #a1b1c6;
        accent-color: #eb3b3b;
        box-shadow: none;
        top: 3px;
        position: relative;
        cursor: pointer;
        &:checked {
            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
            background-color: #eb3b3b !important;
        }
    }
}
